<template>

    <div class="row mb-2 align-items-end">

        <div :class="{'col-6 col-md': true, 'col-12' : inModal}" v-if="dateSelect">
            <label>{{ $t('date_range.select_range') }}</label>
            <e-select
                id="period"
                ref="select_period"
                :placeholder="$t('date_range.select_range')"
                @select="checkSelect"
                v-model="selected_period"
                :options="all_periods_formatted"
                :allow-empty="false"
            >
                    <template slot="option" slot-scope="{ option }">{{ $t('date_range.'+option) }}</template>
                    <template slot="singleLabel" slot-scope="{ option }">{{ $t('date_range.'+option) }}</template>
                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
            </e-select>
        </div>


        <div :class="{'col-6 col-md': true, 'col-12' : inModal}">
            <label>{{ startLabel }}</label>
            <e-datepicker v-model="internalStart" @input="SetPerso()"></e-datepicker>
        </div>

        <div :class="{'col-6 col-md': true, 'col-12' : inModal}">
            <label>{{ endLabel }}</label>
            <e-datepicker v-model="internalEnd" @input="SetPerso()"></e-datepicker>
        </div>
        <div :class="{'col-12 col-md mt-2 mt-md-0': true, 'col-12' : inModal}">
            <button @click.prevent="onSubmit" class="btn btn-block btn-primary">{{ $t('global.rechercher') }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DateRangev2',
    props: {
        start: {
            type: Date,
            default: () => (null)
        },
        startDefault: {
            type: Date,
            default: () => (null)
        },
        startLabel: {
            type: String,
            default: function() { return this.$t('global.date_debut') }
        },
        end: {
            type: Date,
            default: () => (null)
        },
        endDefault: {
            type: Date,
            default: () => (null)
        },
        endLabel: {
            type: String,
            default: function() { return this.$t('global.date_fin') }
        },
        inModal: {
            type: Boolean,
            default: function() { return false }
		},
        submitAtCreation: {
            type: Boolean,
            default: function() { return true }
		},
		persistId: {
			type: String,
			default: ''
		},
        dateSelect: {
            type: Boolean,
            default: function() { return false }
		},
        periodLabel: {
            type: String,
            default: function() { return 'personalise'}
		},
        periodToShow: {
            type: Array,
            default: function() { return null }
        }
    },
    data(){
        return {
            all_periods:[
                'jour',
                'demain',
                'J+2',
                'J+3',
                'hier',
                'semaine',
                'semaine_derniere',
                'semaine_prochaine',
                'mois',
                'mois_dernier',
                'mois_prochain',
                'annee_en_cours',
                'annee_derniere',
                'personalise',
                'six_derniers_mois',
                'trois_derniers_mois'
            ],
            selected_period: 'personalise',
        }
    },
    computed: {
        all_periods_formatted(){
            if(this.periodToShow == null){
                return this.all_periods
            }
            const periods_formatted = this.all_periods.filter(valeur => this.periodToShow.includes(valeur))
            return periods_formatted
        },

		persistParams() {
			return this.persistId != ''
		},
        internalStart: {
            get() {
                return this.start
            },
            set(val) {
				if(val) {
					val.setHours(0)
					val.setMinutes(0)
					val.setSeconds(0)
				}
				this.$emit('update:start', val)
            }
        },
        internalEnd: {
            get() {
                return this.end
            },
            set(val) {
				if(val) {
					val.setHours(23)
					val.setMinutes(59)
					val.setSeconds(59)
				}
				this.$emit('update:end', val)
            }
        }
	},
	created() {
		if(this.persistParams) {
            const period = this.getDateFilters('period')
            if(period){
                this.selected_period = period
                
                if (this.submitAtCreation) {
                    this.$emit('submit')
                }
                return
            }
			const start = this.getDateFilters('begin')
			if(start) {
				this.$emit('update:start', start)
			}
			else if(this.startDefault) {
				this.$emit('update:start', this.startDefault)
			}

			const end = this.getDateFilters('end')
			if(end) {
				this.$emit('update:end', end)
			}
			else if(this.endDefault) {
				this.$emit('update:end', this.endDefault)
			}

            if (this.submitAtCreation) {
                this.$emit('submit')
            }

		} else {
            this.selected_period = this.periodLabel
            this.checkSelect()
        }
	},
    methods: {
        SetPerso(){
            this.selected_period = 'personalise'
        },
        onSubmit() {
			if(this.persistParams) {
				this.saveDateFilters('start', this.internalStart)
				this.saveDateFilters('end', this.internalEnd)
			}
            this.$emit('submit')
		},
		getDateFilters(type='begin') {
            const now = new Date()
			const filters = this.$store.getters["table/getTableDate"](this.persistId)
			if(!filters)  {
                if(type == 'begin') return this.start
                else if(type == 'period') return 'personalise'
				return this.end
			}

            if (type == 'period'){
                if( filters.period) return filters.period
                return 'personalise'
            }
            
            if(type == 'begin') return filters.begin
            return filters.end
		},
		saveDateFilters(type, val) {
            const now = new Date()
			this.$store.commit('table/setTableDate', {
				tableId: this.persistId,
				beginDate: type == 'start' ? val : this.internalStart,
				endDate: type == 'end' ? val : this.internalEnd,
                period: this.selected_period,
			})
		},
        checkSelect(value = null){
            if(value){
                this.selected_period = value
            }
            
            let currentDate = new Date()
            const millisec_in_day =  24 * 60 * 60 * 1000
            switch (this.selected_period) {
                case 'jour':
                    this.internalStart = new Date(currentDate.setHours(0,0,0))
                    this.internalEnd = new Date(currentDate.setHours(23,59,59))
                    break
                case 'hier':
                    this.internalStart = new Date(currentDate.getTime() - millisec_in_day)
                    this.internalEnd = new Date(currentDate.getTime() - millisec_in_day)
                    break
                case 'demain':
                    this.internalStart =  new Date(currentDate.getTime() + millisec_in_day)
                    this.internalEnd = new Date(currentDate.getTime() + millisec_in_day)
                    break
                case 'J+2':
                    this.internalStart =  new Date(currentDate.getTime() + millisec_in_day * 2)
                    this.internalEnd = new Date(currentDate.getTime() + millisec_in_day * 2)
                    break
                case 'J+3':
                    this.internalStart =  new Date(currentDate.getTime() + millisec_in_day * 3)
                    this.internalEnd = new Date(currentDate.getTime() + millisec_in_day * 3)
                    break
                case 'semaine_derniere':
                    this.internalStart = new Date(currentDate.getTime() - (7 + currentDate.getDay()) * millisec_in_day )
                    this.internalEnd = new Date(currentDate.getTime() - (1 + currentDate.getDay()) * millisec_in_day )
                    break
                case 'semaine':
                    this.internalStart = new Date(currentDate.getTime() - currentDate.getDay() * millisec_in_day )
                    this.internalEnd = new Date (currentDate.getTime() + (6 - currentDate.getDay()) * millisec_in_day)
                    break
                case 'semaine_prochaine':
                    this.internalStart = new Date(currentDate.getTime() + (7-currentDate.getDay()) * millisec_in_day )
                    this.internalEnd = new Date (currentDate.getTime() + (13-currentDate.getDay()) * millisec_in_day)
                    break
                case 'mois_dernier':
                    this.internalStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
                    this.internalEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
                    break
                case 'six_derniers_mois':
                    this.internalStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, 1)
                    this.internalEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
                    break
                case 'trois_derniers_mois':
                    this.internalStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1)
                    this.internalEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
                    break
                case 'mois':
                    this.internalStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
                    this.internalEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
                    break
                case 'mois_prochain':
                    this.internalStart = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
                    this.internalEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0)
                    break
                case 'annee_en_cours':
                    this.internalStart = new Date(currentDate.getFullYear(), 0, 1)
                    this.internalEnd = new Date(currentDate.getFullYear(), 11, 31)
                    break
                case 'annee_derniere':
                    this.internalStart = new Date(currentDate.getFullYear() - 1, 0, 1)
                    this.internalEnd = new Date(currentDate.getFullYear() - 1, 11, 31)
                    break
                default:
                    break
            }

            if (this.submitAtCreation) {
                this.onSubmit()
            }
        },
    }
}
</script>
